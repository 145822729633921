import Vue from 'vue'
import Vuex from 'vuex'
import { getLabels, getConfig } from '@/services/MHRService'

Vue.use(Vuex)

const store = new Vuex.Store({

  state: {
    dataUtm: {},
    dataLabels: null,
    dataConfig: null,
    dataPageMeta: null
  },

  getters: {
    dataUtm: state => state.dataUtm,
    dataLabels: state => state.dataLabels,
    dataConfig: state => state.dataConfig,
    dataPageMeta: state => state.dataPageMeta
  },

  actions: {
    async init ({ commit, dispatch }) {
      await Promise.all([
        dispatch('getUtm'),
        dispatch('getLabels'),
        dispatch('getConfig')
      ])
    },
    async getUtm ({ commit }) {
      const {
        utm_source, utm_medium, utm_campaign, utm_id, utm_term, utm_content
      } = Object.fromEntries(new URLSearchParams(window.location.search).entries())
      if (utm_source || utm_medium || utm_campaign || utm_id || utm_term || utm_content) {
        commit('setUtm', { utm_source, utm_medium, utm_campaign, utm_id, utm_term, utm_content })
      }
    },
    async getLabels ({ commit }) {
      getLabels().then(data => commit('setLabelsData', data))
    },
    async getConfig ({ commit }) {
      getConfig().then(data => commit('setConfigData', data))
    },
    setPageMeta ({ commit }, payload) {
      commit('setPageMetaData', payload)
    }

  },

  mutations: {
    setUtm: (state, data) => (state.dataUtm = Object.fromEntries(Object.entries(data).filter(([_, v]) => v != null))),
    setLabelsData: (state, data) => (state.dataLabels = data),
    setConfigData: (state, data) => (state.dataConfig = data),
    setPageMetaData: (state, data) => (state.dataPageMeta = data)
  }
})

export default store
