<template>
  <v-fade-transition mode="out-in">
    <router-view v-if="dataConfig && dataLabels" />
  </v-fade-transition>
</template>

<script>
  export default {
    name: 'App',
    computed: {
      pageMeta () {
        return this.$store.getters.dataPageMeta || this.$store.getters.dataConfig || {}
      }
    },
    metaInfo () {
      const pageTitle = this.pageMeta.page_title || this.pageMeta.m_title || 'Megaworld Hotels & Resorts'
      const pageSubtitle = this.pageMeta.page_subtitle || this.pageMeta.m_description || 'A brand that embodies integrity, professionalism, industry expertise, and trust in the eyes of our investors.'
      const pageImage = this.pageMeta.page_image || 'https://imageresizer.arch.software/megaworld/assets/images/hotels/c9d6e5cbf286f1c4fab9cef248fe035564052265.jpg'

      return {
        title: pageTitle,
        titleTemplate: `%s | ${pageSubtitle}`,
        meta: [
          { property: 'og:title', content: this.pageMeta.page_title || this.pageMeta.m_title },
          { property: 'og:description', content: this.pageMeta.page_subtitle || this.pageMeta.m_description },
          { property: 'og:type', content: 'website' },
          { property: 'og:url', content: `${window.location.origin}${this.$route.fullPath}` },
          { property: 'og:image', content: pageImage }
        ]
      }
    }
  }
</script>

<style lang="sass">
  .extra-padding
    padding-bottom: 96px !important
    padding-top: 96px !important

    @media screen and (max-width: 959px)
      padding-top: 48px !important
      padding-bottom: 48px !important
</style>
