<template>
  <div
    class="image-card"
    :class="{
      [borderColor]: borderColor
    }"
    :style="{
      'width': width,
      'height': height,
      borderRadius: [borderRadius],
      borderWidth: [borderWidth]
    }"
  >
    <img :src="image" class="image-card__mask" alt="">
    <div class="image-card__content text-center text-lg-left w-100">
      <slot />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ImageCard',
    props: {
      image: {
        type: String,
        required: true
      },
      height: {
        type: String,
        default: '100%'
      },
      width: {
        type: String,
        default: '100%'
      },
      borderColor: {
        type: String,
        default: 'transparent'
      },
      borderRadius: {
        type: String,
        default: '0px'
      },
      borderWidth: {
        type: String,
        default: '0px'
      }
    }
  }
</script>

<style lang="scss" scoped>
.image-card {
  height: 276px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  max-width: 100vw;
  box-sizing: border-box;

  border-style: solid;

  &__content {
    padding: 12px 12px;
    position: relative;
  }

  &__mask {
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
</style>
