// Vuetify Documentation https://vuetifyjs.com

import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
// import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'fa'
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#42A5F6',
        secondary: '#050B1F',
        accent: '#204165'
      },
      dark: {
        primary: '#50778D',
        secondary: '#0B1C3D',
        accent: '#204165'
      }
    }
  }
})
