import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import vuetify from '@/plugins/vuetify'
import VueGtm from '@gtm-support/vue2-gtm'
// import VueGtag from 'vue-gtag'

import '@/plugins'
import store from '@/store'

// Vue.use(VueGtag, {
//   config: { id: process.env.VUE_APP_MHR_GTAG },
//   pageTrackerTemplate (to) {
//     const detail = to.params.promoName || to.params.featureName

//     switch (to.name) {
//       case 'Events Detail':
//       case 'Venues Detail':
//       case 'Features Press Detail':
//       case 'Features Blog Detail':
//       case 'Promotions Room Detail':
//       case 'Promotions F&B Detail':
//         return {
//           page_title: detail ? [to.name, detail].join(': ') : to.name,
//           page_path: to.path
//         }
//       default:
//         return {
//           page_title: to.name,
//           page_path: to.path
//         }
//     }
//   }
// }, router)

Vue.use(VueGtm, {
  debug: true,
  id: process.env.VUE_APP_MHR_GTM,
  loadScript: true,
  vueRouter: router
})

Vue.config.productionTip = false

Vue.mixin({

  data () {
    return {
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      socialIcons: {
        facebook: 'fa-brands fa-facebook',
        linkedin: 'fa-brands fa-linkedin',
        twitter: 'fa-brands fa-twitter',
        instagram: 'fa-brands fa-instagram',
        youtube: 'fa-brands fa-youtube',
        viber: 'fa-brands fa-viber',
        tiktok: 'fa-brands fa-tiktok'
      },

      breakpoint: 1264,
      baseUrl: process.env.BASE_URL
    }
  },

  computed: {
    isMobile () { return this.$vuetify.breakpoint.mdAndDown },

    dataUtm () { return this.$store && this.$store.getters.dataUtm },
    dataLabels () { return this.$store.getters.dataLabels },
    dataConfig () { return this.$store.getters.dataConfig }
  },

  methods: {
    toOrdinal (n) {
      n = parseInt(n)
      return n + (
        n % 10 === 1 && n % 100 !== 11 ? 'st'
        : n % 10 === 2 && n % 100 !== 12 ? 'nd'
          : n % 10 === 3 && n % 100 !== 13 ? 'rd'
            : 'th'
      )
    },

    gtagBookEvent (page, hotel) {
      // this.$gtag?.event('book', {
      //   event_label: [page, hotel].join(': ')
      // })
      this.$gtm.trackEvent({
        category: 'book',
        action: 'click',
        label: [page, hotel].join(': ')
      })
    },

    toQueryUrl (url, queries) {
      url = new URL(url)
      Object.entries(queries).forEach(([k, v]) => { url.searchParams.set(k, v) })
      return url.href
    },

    formatDate (date) {
      if (date) {
        date = new Date(date)
        return [date.getDate(), date.getMonth() + 1, date.getFullYear()].join('/')
      } else return ''
    },

    formatDateFull (date) {
      if (date) {
        date = new Date(date)
        return `${ this.months[date.getMonth()] }, ${ this.toOrdinal(date.getDate()) } ${ date.getFullYear() }`
      } else return ''
    },

    convertHex (color) {
      if (color) {
        color = color.replace('#', '')
        const r = parseInt(color.substring(0, 2), 16)
        const g = parseInt(color.substring(2, 4), 16)
        const b = parseInt(color.substring(4, 6), 16)
        return [r, g, b].join()
      }
    },

    copyText (text, alertText) {
      navigator.clipboard.writeText(text)
      alert(alertText)
    }
  }
})

new Vue({
  router,
  vuetify,
  store,
  mounted: () => store.dispatch('init'),
  render: h => h(App)
}).$mount('#app')
